import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../PrimaryButton'
import { APPLY_FDR_URL } from '../../../common/constants'
import { useLeadId } from '../../../common/lead-id'
import { useRouter } from 'next/router'
import { buildQueryString } from '../../../common/utils'

const StickyButtonComponent = ({ label, url, className, parentClassName }) => {
  const leadId = useLeadId()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )

  return (
    <div
      className={`fixed bottom-0 left-0 z-10 mx-auto w-full max-w-1400 bg-white p-4 shadow-stickyButton duration-500 ease-in ${parentClassName}`}
      id="sticky-button"
      data-testid="sticky-button"
    >
      <PrimaryButton
        isLeadCta
        sticky={true}
        title={label}
        href={`${url}?${query}`}
        rel="nofollow noreferrer"
        target="_blank"
        className={`block w-full rounded-lg bg-red-950 px-5 py-3.5 text-center text-lg font-bold leading-snug tracking-normal text-white antialiased ${className}`}
      />
    </div>
  )
}

StickyButtonComponent.defaultProps = {
  label: 'Get debt help now',
  url: APPLY_FDR_URL(),
  className: '',
  parentClassName: '',
}

StickyButtonComponent.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
}

export default StickyButtonComponent
